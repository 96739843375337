<template>
<div class="modal fade" id="mobile_modal_info" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="mobile_modal_info">Подробная информация</h5>
        <button type="button" data-bs-dismiss="modal" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="table">
          <table class="custom-table">
            <tbody v-if="orders.length !== 0" class="modal-info-table">
              <tr>
                <td><img :src="orders[itemId].img" alt=""></td>
              </tr>
              <tr>
                <td>Артикул продавца</td>
                <td>{{ orders[itemId].supplierArticle }}</td>
              </tr>
              <tr>
                <td>Статус</td>
                <td>{{ orders[itemId].status }}</td>
              </tr>
              <tr>
                <td>Заказ</td>
                <td>{{ orders[itemId].date }}</td>
              </tr>
              <tr v-if="orders[itemId].status == 'Доставлен'">
                <td>Продажа</td>
                <td><span >{{ orders[itemId].sale_date }}</span></td>
              </tr>
              <tr v-if="orders[itemId].status == 'Отменен'">
                <td>Отмена</td>
                <td><span>{{ orders[itemId].cancelDate.replace('T', ' ') }}</span></td>
              </tr>
              <tr>
                <td>Себестоимость</td>
                <td>{{ orders[itemId].cost_price }}</td>
              </tr>
              <tr>
                <td>Стоимость</td>
                <td>{{ orders[itemId].finishedPrice }}</td>
              </tr>
              <tr v-if="orders[itemId].status == 'Доставлен'">
                <td>Сумма продажи</td> 
                <td><span>{{ orders[itemId].sale_sum }}</span></td>
              </tr>
              <tr>
                <td>Комиссия</td>
                <td>{{ orders[itemId].commission }}</td>
              </tr>
              <tr>
                <td>Эквайринг</td>
                <td>{{ orders[itemId].acquiring }}</td>
              </tr>
              <tr>
                <td>Логистика</td>
                <td>{{ orders[itemId].logistics }}</td>
              </tr>
              <tr v-if="orders[itemId].status !== 'В процессе'">
                <td>Прибыль</td>
                <td>
                  <span v-if="orders[itemId].status == 'Доставлен'">{{ orders[itemId].sale_sum - orders[itemId].logistics}}</span>
                  <span v-if="orders[itemId].status == 'Отменен' || orders[itemId].status == 'Возврат'">{{ -orders[itemId].logistics }}</span>
                </td>
              </tr>
              <tr>
                <td>Номер заказа</td>
                <td>{{ orders[itemId].gNumber }}</td>
              </tr>
              <tr>
                <td>География</td>
                <td>
                  Откуда: {{ orders[itemId].warehouseName }} ({{ orders[itemId].store }})
                  <br> Куда: {{ orders[itemId].oblastOkrugName }}, {{ orders[itemId].regionName }}
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


    <div class="container-fluid">
              
      <div class="alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
      </div>
        
      <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
        <div class="col content-title">
          <h1>Заказы</h1></div>
      </div>
      <div class="content-block">
        <div class="accordion d-lg-none" id="filterOpen">
            <div class="accordion-item border-0">
                <h2 class="accordion-header">
                <button class="accordion-button collapsed ps-0" style="width: fit-content;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                   <span class="pe-3">Показать параметры</span>
                 </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#filterOpen">
                <div class="accordion-body">
                  <div class="pageFilter">
          <div class="js-pageFilter__form">
            <div class="row row-cols-lg-auto align-items-end">
              <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="order_date_f07f6044" class="form-label">Дата заказа</label>
                <div class="input-group">
                  <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="order_date_f07f6044" autocomplete="off"><span class="input-group-text"><label for="order_date_f07f6044"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
                <input type="hidden" name="filter[start_order_date]" class="js-daterangepicker__start" value="">
                <input type="hidden" name="filter[end_order_date]" class="js-daterangepicker__end" value="">
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brand_8c4d3a94" class="form-label">Бренд</label>
                <select class="form-select" v-model="filter.brand">
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store">
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace">
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Артикул продавца</label>
                <input type="text" class="form-control" v-model="filter.article" autocomplete="off">
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="report_number_068ef255" class="form-label">Номер заказа</label>
                <input type="text" class="form-control" value="" v-model="filter.order_num" autocomplete="off">
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Статус</label>
                <select class="form-select" v-model="filter.status" tabindex="-1" aria-hidden="true">
                  <option value="">Не выбрано</option>
                  <option>В процессе</option>
                  <option>Доставлен</option>
                  <option>Отменен</option>
                  <option>Возврат</option>
                </select>
              </div>

              <input type="hidden" name="filter[sortBy]" value="created_at">
              <input type="hidden" name="filter[orderBy]" value="desc">
              <div class="col-md mb-3">
                <div class="btn-toolbar">
                  <div class="btn-group me-2">
                    <button class="btn btn-primary" @click="applyFilter(0)">Применить</button>
                  </div>
                  <div class="btn-group"><a href="/orders" class="btn btn-outline-secondary">Сбросить</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
                </div>
                </div>
            </div>
          </div>
        
        <div class="pageFilter d-lg-block d-none">
          <div class="js-pageFilter__form">
            <div class="row row-cols-lg-auto align-items-end">
              <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="order_date_f07f6044" class="form-label">Дата заказа</label>
                <div class="input-group">
                  <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="order_date_f07f6044" autocomplete="off"><span class="input-group-text"><label for="order_date_f07f6044"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
                <input type="hidden" name="filter[start_order_date]" class="js-daterangepicker__start" value="">
                <input type="hidden" name="filter[end_order_date]" class="js-daterangepicker__end" value="">
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brand_8c4d3a94" class="form-label">Бренд</label>
                <select class="form-select" v-model="filter.brand">
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store">
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace">
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Артикул продавца</label>
                <input type="text" class="form-control" v-model="filter.article" autocomplete="off">
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="report_number_068ef255" class="form-label">Номер заказа</label>
                <input type="text" class="form-control" value="" v-model="filter.order_num" autocomplete="off">
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Статус</label>
                <select class="form-select" v-model="filter.status" tabindex="-1" aria-hidden="true">
                  <option value="">Не выбрано</option>
                  <option>В процессе</option>
                  <option>Доставлен</option>
                  <option>Отменен</option>
                  <option>Возврат</option>
                </select>
              </div>

              <input type="hidden" name="filter[sortBy]" value="created_at">
              <input type="hidden" name="filter[orderBy]" value="desc">
              <div class="col-md mb-3">
                <div class="btn-toolbar">
                  <div class="btn-group me-2">
                    <button class="btn btn-primary" @click="applyFilter(0)">Применить</button>
                  </div>
                  <div class="btn-group"><a href="/orders" class="btn btn-outline-secondary">Сбросить</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

      <div v-if="$store.state.user_info.check == 0">
      <div class="modal-loader d-flex justify-content-center" v-if="loading">
        <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
      </div>
      <div class="content-block" v-else>
        <div class="order-page-container">
          <p>Заказов за период: <span>{{ info.orders }}</span> | Заказов сегодня: <span>{{ info.orders_today }}</span> | Продаж сегодня: <span id="sale_today_cnt" data-path="/api/v1/sale/today-cnt">{{ info.sales_today }}</span></p>
        </div>
        <div class="table">
          <button class="btn btn-primary my-3"><json-excel
            :fetch="loadJsonData"
            type="xlsx"
            name="orders.xls"
            :export-fields = exportFields
          >
          <div class="modal-loader mt-0 d-flex justify-content-center" v-if="json_data_loading">
            <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
            <span v-else>Экспорт в Excel</span>
          </json-excel></button>
          <table class="custom-table d-sm-block d-none">
            <thead>
              <tr>
                <td rowspan="2" colspan="2" class="p-1 p-lg-1 text-nowrap">Товар</td>
                <td rowspan="2" class="p-1 p-lg-1 text-nowrap">Статус</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap text-center">Заказ</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap ">Продажа</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap ">Отмена</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap">Себестоимость</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap">Стоимость</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap">Сумма продажи</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap">Комиссия</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap">Эквайринг</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap">Логистика</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap">Прибыль</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap">Номер заказа</td>
                <td rowspan="1" class="p-1 p-lg-1 text-nowrap">География</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in orders" :class="{'table-primary': item.status=='В процессе', 'table-danger': item.status=='Отменен' || item.status=='Возврат', 'table-success': item.status=='Доставлен'}">
                <td style="text-align: center;"><img :src="item.img" alt=""></td>
                <td style="text-align: left">Артикул: {{ item.supplierArticle }}</td>
                <td rowspan="1">{{ item.status }}</td>
                <td rowspan="1">{{ item.date }}</td>
                <td rowspan="1"><span v-if="item.status == 'Доставлен'">{{ item.sale_date }}</span></td>
                <td rowspan="1"><span v-if="item.status == 'Отменен'">{{ item.cancelDate.replace('T', ' ') }}</span></td>
                <td>{{ item.cost_price }}</td>
                <td>{{ item.finishedPrice }}</td>
                <td rowspan="1"><span v-if="item.status == 'Доставлен'">{{ item.sale_sum }}</span></td>
                <td rowspan="1">{{ item.commission }}</td>
                <td rowspan="1">{{ item.acquiring }}</td>
                <td rowspan="1">{{ item.logistics }}</td>
                <td>
                  <span v-if="item.status == 'Доставлен'">{{ item.sale_sum - item.logistics}}</span>
                  <span v-if="item.status == 'Отменен' || item.status == 'Возврат'">{{ -item.logistics }}</span>
                </td>
                <td style="text-align: left;" rowspan="1">{{ item.gNumber }}</td>
                <td rowspan="1" style="text-align: left;">
                  Откуда: {{ item.warehouseName }} ({{ item.store }})
                  <br> Куда: {{ item.oblastOkrugName }}, {{ item.regionName }}
                </td>
              </tr>
              
            </tbody>
          </table>

          <table class="custom-table d-sm-none">
            <thead>
              <tr>
                <td rowspan="2" colspan="2">Артикул</td>
                <td>Статус</td>
                <td></td>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in orders" :class="{'table-primary': item.status=='В процессе', 'table-danger': item.status=='Отменен' || item.status=='Возврат', 'table-success': item.status=='Доставлен'}">
                <td style="text-align: center;"><img :src="item.img" alt=""></td>
                <td style="text-align: left">Артикул: {{ item.supplierArticle }}</td>
                <td rowspan="1">{{ item.status }}</td>
                <td><a class="show_mobile_modal_icon" data-bs-toggle="modal" data-bs-target="#mobile_modal_info" @click="itemId = index"><i class="bi bi-eye"></i></a></td>
              </tr>
            </tbody>
          </table>
          
        </div>
        <div class="order-pagination">
          <nav aria-label="Page navigation example">
            <ul class="pagination mt-3">
              <li class="page-item" style="cursor: pointer" :class="{active: pageNum == page+1}" v-for="pageNum in pages_count" @click="applyFilter(pageNum-1)">
                <span class="pagination-button">{{ pageNum }}</span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      </div>
      </div>
</template>

<script>
import axios from 'axios'
import JsonExcel from "vue-json-excel3"

export default{
  props: {
    getArticle: String
  },
  components: {
    JsonExcel
  },
  data(){
    return {
      info: [],
      orders: [],
      page: 0,
      pages_count: 0,
      filter: {
        store: '',
        brand: '',
        article: '',
        order_num: '',
        status: '',
        marketplace: ''
      },
      stores: [],
      brands: [],
      loading: true,
      itemId: 0,
      json_data: [],
      json_data_loading: false,
      loadJsonData: async ()=>{
        this.json_data_loading = true
        let post_data = {
          start_date: $('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD'),
          end_date:  $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD'),
          store: this.filter.store,
          brand: this.filter.brand,
          article: this.filter.article,
          order_num: this.filter.order_num,
          status: this.filter.status,
          marketplace: this.filter.marketplace,
          user_id: this.$store.state.user_info.id,
        }

        const response = await axios.post('https://tapwill.ru/api/export_orders.php', post_data)
          this.json_data = response.data
          console.log(response.data)
          this.json_data_loading = false
          return this.json_data
        
      },
      exportFields: {
        "Артикул продавца": 'supplierArticle',
        "Статус": 'status',
        "Заказ": 'date',
        "Продажа": 'sale_date',
        "Отмена": 'cancelDate',
        "Себестоимость": 'cost_price',
        "Стоимость": 'finishedPrice',
        "Сумма продажи": 'sale_sum',
        "Комиссия": 'commission',
        "Эквайринг": 'acquiring',
        "Логистика": 'logistics',
        "Прибыль": 'profit',
        "Номер заказа": 'gNumber',
        "Откуда": 'warehouseName',
        "Область доставки": 'oblastOkrugName',
        "Регион доставки": 'regionName',
        "Магазин": 'store',
      }
    }
  },
  methods: {
    loadData(start, end){
      this.loading = true
      let post_data = {
        start_date: start,
        end_date: end,
        store: this.filter.store,
        brand: this.filter.brand,
        article: this.filter.article,
        order_num: this.filter.order_num,
        status: this.filter.status,
        marketplace: this.filter.marketplace,
        user_id: this.$store.state.user_info.id,
        page: this.page
      }

      axios.post('https://tapwill.ru/api/get_orders.php', post_data).then((response)=>{
        this.info = response.data[0]
        this.orders = response.data[1]
        this.pages_count = response.data[2]
        console.log(response.data)
        this.loading = false
    })
    },
    applyFilter(n){
      this.page = n
      this.loadData($('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD'),  $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD'))
    },

    // loadJsonData(){
      
      
    // }
  },
  created(){
    if(this.$props.getArticle){
      this.filter.article = this.$props.getArticle
    }
    this.loadData(moment().subtract(3, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    axios.post('https://tapwill.ru/api/get_store.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.stores = response.data
      console.log(this.stores)
    })
    axios.post('https://tapwill.ru/api/get_brands.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.brands = response.data
      console.log(this.brands)
    })
  },
  mounted(){
    let start = moment().subtract(3, 'days');
    let end = moment();

    function cb(start, end) {
        $('.js-daterangepicker span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    $('.js-daterangepicker').daterangepicker({
      "locale": {
        "format": "DD.MM.YYYY",
        "separator": " - ",
        "applyLabel": "Сохранить",
        "cancelLabel": "Назад",
        "Custom Range": "Произвольно",
        "daysOfWeek": [
            "Вс",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ],
        "firstDay": 1
    },
      
      opens: 'left',
      autoUpdateInput: true,
      startDate: start,
      endDate: end,
      ranges: {
           'Сегодня': [moment(), moment()],
           'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
           'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
           'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
           'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
           'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      }, (start, end) => {
        $('.js-daterangepicker').data('daterangepicker').startDate = start
        $('.js-daterangepicker').data('daterangepicker').endDate = end
        this.loadData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
      }, cb);


    cb(start, end);

    $('li[data-range-key="Custom Range"]').text('Произвольно')
  }
}
</script>