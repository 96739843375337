import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    user_info:{},
    user: false,
    dashboard_data: {
      items: {
        'orders': {
          'money': 0,
          'count': 0
        },
        'sales': {
          'money': 0,
          'count': 0
        },
        'return': {
          'money': 0,
          'count': 0
        },
        'cancel': {
          'money': 0,
          'count': 0
        },
        'buyout': 0,
        'average_orders': {
            'money': 0,
            'count': 0
        },
        'average_sales': {
          'money': 0,
          'count': 0
        },
        'average_bill': 0
      },
    expenses: {
      'adv': 0,
      'adv_balance': 0,
      'adv_bank': 0,
      'adv_bonus': 0,
      'commission': 0,
      'logistics': 0,
      'storage': 0
    },
    finance: {
      'gain': 0,
      'cost_price': 0,
      'marginality': 0,
      'oper': 0,
      'tax': 0,
      'profit': 0,
      'average_profit': 0,
      'expenses': 0
    },
    profit: {
      'roi': 0,
      'vp': 0
    },
    stocks: {
      'rest': 0,
      'fbo1': 0,
      'fbo2': 0
    },
    graph: {
      'sales': [],
      'orders': []
    },
    deficit: []
    },

    dashboardPeriod_data:{
      items: {
        'orders': {
          'money': 0,
          'count': 0
        },
        'sales': {
          'money': 0,
          'count': 0
        },
        'return': {
          'money': 0,
          'count': 0
        },
        'cancel': {
          'money': 0,
          'count': 0
        },
        'buyout': 0,
        'average_orders': {
            'money': 0,
            'count': 0
        },
        'average_sales': {
          'money': 0,
          'count': 0
        },
        'average_bill': 0
      },
      expenses: {
        'adv': 0,
        'commission': 0,
        'logistics': 0
      },
      finance: {
        'gain': 0,
        'marginality': 0,
        'gross': 0,
        'profit': 0,
        'average_profit': 0,
        'expenses': 0
      }
    }
  },
  getters: {
  },
  mutations: {
    authUser(state, elem){
      state.user = true;
      state.user_info = elem;
    },
    exitUser(state){
      state.user = false;
      state.user_info = {};
    },
    updateUser(state, elem){
      state.user_info.name = elem.name;
      state.user_info.phone = elem.phone;
    },
    updatePassword(state, elem){
      state.user_info.password = elem
    },
    updateNalog(state, elem){
      state.user_info.nalog_percent = elem.nalog_percent;
      state.user_info.nalog_option = elem.nalog_option;
    },
    insertDashboardData(state, elem){
      state.dashboard_data = elem
    },
    insertDashboardPeriodData(state, elem){
      state.dashboardPeriod_data = elem
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState(
      {
        paths: ['user', 'user_info']
      }
    )
  ]
})
