<template>
  <div v-if="userUrl == '/' || userUrl == '/auth' || userUrl == '/reg' || userUrl == '/forgot-password'">
    <router-view />
    <main-footer />
  </div>
  
  <div class="row" v-else>
    <div class="col-lg-2 d-none d-lg-block">
        <main-menu />
    </div>
    <div class="d-block d-lg-none">
      <main-menu />
    </div>
    <div class="col-lg-10 col-12">
      <main class="pt-lg-0 pt-5 mt-5">
        <router-view/>
      </main>
      <main-footer />
    </div>
  </div>
  
</template>

<script>
import MainFooter from '@/components/MainFooter.vue';
import MainMenu from '@/components/MainMenu.vue';

export default{
  components: {
    MainMenu, MainFooter
  },

  data(){
    return{
      userUrl: window.location.pathname
    }
  }
}
</script>

<style lang="scss">
// @import './assets/style/main.scss', './assets/style/global.scss', './assets/style/orders.scss', './assets/style/custom.scss', './assets/style/style.scss';
@import './assets/style/style.scss';
</style>