<template>
    <div class="authBg">

    <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xxl-4 col-lg-5">
        <div class="card">
          <div class="card-header pt-4 pb-4 text-center bg-primary"><a href="/" class="card-header__logo"><span>TAPWILL</span></a></div>
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <h3 class="text-dark-50 text-center mt-0 fw-bold">Авторизация</h3>
              <p class="text-muted mb-4">Введите свой email и пароль для авторизации в сервисе</p>
            </div>
            <form @submit.prevent="sendForm" id="signIn">
              <div class="mb-3">
                <label for="login" class="form-label">Email</label>
                <input class="form-control" v-model="form_data.mail" name="login" type="email" id="login" placeholder="Введите ваш email" required>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Пароль</label>
                <div class="input-group input-group-merge">
                  <input type="password" name="password" v-model="form_data.password" id="password" class="form-control" placeholder="Введите ваш пароль" required>
                  
                </div>
                <div class="small mt-2 text-end"><a href="/forgot-password" class="text-muted">Забыли пароль?</a></div>
              </div>
              <div class="mb-3 text-center">
                <button class="btn btn-primary" name="signIn" type="submit"> Войти
                </button>
              </div>
              
            </form>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">
              У вас нет аккаунта? <a href="/reg" class="text-muted ms-1"><b>Зарегистрируйтесь</b></a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<style lang="scss" scoped>
.main-menu, .main-footer{
    display: none !important;
}
@import url('../assets/style/auth.scss');
</style>

<script>
import axios from 'axios';

export default{
  data(){
    return{
      form_data: {
        mail: '',
        password: ''
      }
      
    }
  },

  methods: {
    sendForm(){
      axios.post('https://tapwill.ru/api/auth.php', this.form_data).then((response)=>{
        if(response.status == 200){
          if(response.data == 0){
            alert('Пользователь не найден')
          }
          else{
            this.$store.commit('authUser', response.data)
            window.location.href='/user-stores'
          }
        }
        else{
          alert('Произошла ошибка! Попробуйте позднее')
        }
      })
    }
  },
  created(){
    if(this.$store.state.user == true){
      window.location.href = '/dashboard'
    }
  }
}
</script>