<template>
  <div class="modal fade" id="mobile_modal_info" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="mobile_modal_info">Подробная информация</h5>
        <button type="button" data-bs-dismiss="modal" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="table">
          <table class="custom-table">
            <tbody v-if="items.length !== 0" class="modal-info-table">
              <tr>
                <td><img :src="items[itemId].img" alt=""></td>
              </tr>
              <tr>
                <td>Артикул продавца</td>
                <td>{{ items[itemId].supplierArticle }}</td>
              </tr>
              <tr>
                <td>Артикул {{ items[itemId].marketplace }}</td>
                <td>{{ items[itemId].nmId }}</td>
              </tr>
              <tr>
                <td>Цена</td>
                <td>{{ items[itemId].Price - items[itemId].Discount }}</td>
              </tr>
              <tr>
                <td>Себестоимость, руб.</td>
                <td>{{ items[itemId].cost_price }}</td>
              </tr>
              <tr>
                <td>Бренд</td>
                <td>{{ items[itemId].brand }}</td>
              </tr>
              <tr>
                <td>Магазин</td>
                <td>{{ items[itemId].store }}</td>
              </tr>
              <tr>
                <td>Размер</td>
                <td>{{ items[itemId].techSize }}</td>
              </tr>
              <tr>
                <td>Всего шт.</td>
                <td>{{ items[itemId].quantityFull }}</td>
              </tr>
              <tr>
                <td>В пути к клиенту, шт.</td>
                <td>{{ items[itemId].inWayToClient }}</td>
              </tr>
              <tr>
                <td>В пути от клиента, шт.</td>
                <td>{{ items[itemId].inWayFromClient }}</td>
              </tr>
              <tr>
                <td>В продаже, шт.</td>
                <td>{{ items[itemId].quantity }}</td>
              </tr>
              <tr>
                <td>Закончится к</td>
                <td v-if="items[itemId].date_left == '1970-01-01'"></td>
                <td v-else>{{ items[itemId].date_left }}</td>
              </tr>
              <tr>
                <td>О товаре</td>
                <td>
              <div class="my-auto d-flex justify-content-center">
                <a :href="'/itemsinfo/'+items[itemId].supplierArticle" class="d-block">Сводка</a>
                <span class="d-block mx-2" style="opacity: 0.8; font-size: 1.2em;"> | </span>
                <a :href="'/orders/'+items[itemId].supplierArticle" class="d-block">Заказы</a>
              </div>
            </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">

  <div class="alert alert-warning" role="alert">
    <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
  </div>
      
  <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
    <div class="col content-title">
      <h1>Остатки по товарам</h1></div>
  </div>
  <div class="content-block">
    <div class="accordion d-lg-none" id="filterOpen">
            <div class="accordion-item border-0">
                <h2 class="accordion-header">
                <button class="accordion-button collapsed ps-0" style="width: fit-content;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                   <span class="pe-3">Показать параметры</span>
                 </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#filterOpen">
                <div class="accordion-body">

                  <div class="pageFilter">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
          <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brand_8c4d3a94" class="form-label">Бренд</label>
                <select class="form-select" v-model="filter.brand">
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store">
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace">
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Артикул продавца</label>
                <input type="text" class="form-control" v-model="filter.article" autocomplete="off">
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter(0)">Применить</button>
              </div>
              <div class="btn-group"><a href="/restitems" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>

                  </div>
                  </div>
                  </div>
                  </div>

    <div class="pageFilter d-lg-block d-none">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
          <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brand_8c4d3a94" class="form-label">Бренд</label>
                <select class="form-select" v-model="filter.brand">
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store">
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace">
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Артикул продавца</label>
                <input type="text" class="form-control" v-model="filter.article" autocomplete="off">
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter(0)">Применить</button>
              </div>
              <div class="btn-group"><a href="/restitems" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

  <div v-if="$store.state.user_info.check == 0">
  <div class="modal-loader d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
  </div>
  <div class="content-block mt-5" v-else>
    <div class="table">
      <table class="custom-table d-sm-block d-none">
        <thead>
          <tr>
            <td colspan="2">Товар</td>
            <td>Цена</td>
            <td>Себестоимость, руб.</td>
            <td>Бренд</td>
            <td>Магазин</td>
            <td>Размер</td>
            <td>Всего шт.</td>
            <td>В пути
              <br>к клиенту</td>
            <td>В пути
              <br>от клиента</td>
            <td>В продаже</td>
            <td>Закончится к</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" class="table-light">
            <td rowspan="1" style="border-right: none;"><img :src="item.img" alt="" style="max-width: 35px;display: block;margin: 2px auto;"></td>
            <td rowspan="1" style="text-align: left; border-left: none;">
              {{ item.supplierArticle }}
              <br><span>{{ item.marketplace }}:<br>{{ item.nmId }}</span></td>
            <td>{{ item.Price - item.Discount }}</td>
            <td>{{ item.cost_price }}</td>
            <td>{{ item.brand }}</td>
            <td rowspan="1">{{ item.store }}</td>
            <td class="size">{{ item.techSize }}</td>
            <td>{{ item.quantityFull }}</td>
            <td>{{ item.inWayToClient }}</td>
            <td>{{ item.inWayFromClient }}</td>
            <td>{{ item.quantity }}</td>
            <td v-if="item.date_left == '1970-01-01'"></td>
            <td v-else>{{ item.date_left }}</td>
            <td>
              <div class="my-auto d-flex justify-content-center">
                <a :href="'/itemsinfo/'+item.supplierArticle" style="font-size: 1.2em;" class="d-block">Сводка</a>
                <span class="d-block mx-2" style="opacity: 0.8; font-size: 1.2em;"> | </span>
                <a :href="'/orders/'+item.supplierArticle" style="font-size: 1.2em;" class="d-block">Заказы</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

          <table class="custom-table d-sm-none">
            <thead>
              <tr>
                <td rowspan="2" colspan="2">Товар</td>
                <td>Всего, шт</td>
                <td></td>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in items">
                <td><img :src="item.img" alt=""></td>
                    <td><span>{{ item.supplierArticle }}</span>
                      <br><span>{{ item.marketplace }}: {{ item.nmId }}</span></td>
                <td>{{ item.quantityFull }}</td>
                <td><a class="show_mobile_modal_icon" data-bs-toggle="modal" data-bs-target="#mobile_modal_info" @click="itemId = index"><i class="bi bi-eye"></i></a></td>
              </tr>
            </tbody>
          </table>

    </div>
        <div class="order-pagination">
          <nav aria-label="Page navigation example">
            <ul class="pagination mt-3">
              <li class="page-item" style="cursor: pointer" :class="{active: pageNum == page+1}" v-for="pageNum in pages_count" @click="applyFilter(pageNum-1)">
                <span class="pagination-button">{{ pageNum }}</span>
              </li>
            </ul>
          </nav>
        </div>
  </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';

export default{
    data(){
        return{
            stores: [],
            brands: [],
            page: 0,
            pages_count: 0,
            filter: {
              store: '',
              brand: '',
              article: '',
              marketplace: ''
            },
            items: [],
            loading: true,
            itemId: 0
        }
    },
    methods: {
      updateInfo(start, end){
        this.loading = true
        let post_data = {
          start_date: start,
          end_date: end,
          store: this.filter.store,
          brand: this.filter.brand,
          article: this.filter.article,
          marketplace: this.filter.marketplace,
          user_id: this.$store.state.user_info.id,
          page: this.page
        }
        axios.post('https://tapwill.ru/api/get_data_rest.php', post_data).then((response)=>{
          console.log(response.data)
          this.items = response.data[0]
          this.pages_count = response.data[1]
          this.loading = false
        });
      },
      applyFilter(n){
        this.page = n
        this.updateInfo()
      }
    },
    created(){
      this.updateInfo()
      axios.post('https://tapwill.ru/api/get_store.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
        this.stores = response.data
        console.log(this.stores)
      })
      axios.post('https://tapwill.ru/api/get_brands.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
        this.brands = response.data
        console.log(this.brands)
      })
    }
}
</script>