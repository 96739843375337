<template>
    <div class="container-fluid">

      <div class="alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
      </div>

  <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
    <div class="col content-title">
      <h1>Ваши магазины</h1></div>
  </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

  <div v-else>

  <!-- Кнопка добавления -->
  <div class="mt-4">
    <div class="d-flex flex-row align-items-center">
      <div class="d-lg-flex d-block">
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">Подключить магазин</button>
          
          <ul class="dropdown-menu marketplaceSelect__list" aria-labelledby="dropdownMenuButton1">
            <li>
              <h6 class="dropdown-header">Выберите маркетплейс</h6></li>
            <li>
              <a class="dropdown-item marketplaceSelect_item py-2" href="#" data-bs-toggle="modal" data-bs-target="#addMarketplaceAccount" @click="addStore = 'wildberries'"><img src="../assets/images/wildberries.svg" alt="Wildberries" class="marketplaceSelect__wildberries"></a>
            </li>
            <li>
              <a class="dropdown-item marketplaceSelect_item py-2" href="#" data-bs-toggle="modal" data-bs-target="#addMarketplaceAccountOzon" @click="addStore = 'ozon'"><img src="../assets/images/ozon.svg" alt="Ozon" class="marketplaceSelect__ozon"></a>
            </li>
          </ul>
        </div>
        <a href="/how-to-use" target="_blank" class="mt-2 mx-lg-auto ms-lg-3">Как подключить магазин</a>
      </div>
    </div>
  </div>

  <div class="content-block mt-lg-5">
    <div class="marketplaceAccount__list">
      <div class="row">

        <div class="col-sm-12 col-md-6 col-xl-4 mt-4" v-for="store in userStores">
          <div class="card">
            <div class="card-header">
              <div class="marketplaceAccount__header d-flex justify-content-start my-2">
                <div class="marketplaceAccount__logo" v-if="store.marketplace == 'wildberries'"><img src="../assets/images/wb.png" style="height: 50px;" alt="Wildberries"></div>
                <div class="marketplaceAccount__logo" v-if="store.marketplace == 'ozon'"><img src="../assets/images/ozon.png" style="height: 50px;" alt="Ozon"></div>
                <h4 class="marketplaceAccount__name my-auto ms-2">{{ store.title }}</h4>
                <!-- <a @click="itemEdit = store" data-bs-toggle="modal" data-bs-target="#editMarketplaceAccount"><i class="bi bi-pencil-square" style="position: absolute; right: 15px; font-size: 1.2em;"></i></a> -->
              </div>
                
              </div>
            <div class="card-body">

                  <p>Статус: 
                  <span style="color: #ffc107;" v-if="store.active == 0">Загрузка данных с маркетплейса</span>
                  <span style="color: #009688;" v-if="store.active == 1">Активный</span>
                  <span style="color: #dc3545;" v-if="store.active == 2">Приостановлен</span>
                  </p>
                  <p style="font-size: 0.8em;">Посл. обновление: {{ store['UpdTime'] }}</p>
                  <p style="font-size: 0.8em;">Первич. загрузка: {{ store['date'] }}</p>
                  <button type="button" :class="{'disabled': store.active == 0}" @click="deleteStore(store.title)" class="btn btn-danger mt-2"><i class="bi bi-trash"></i> Удалить</button>

            </div>
          </div>
        </div>

      </div>
      <div class="marketplaceAccount__info mt-4">
        <div class="alert alert-warning" role="alert">
          <h4>Первичная загрузка данных </h4>
          <p>После добавление нового магазина мы начинам первичную загрузку данных за последние 90 дней относительно даты создания магазина в нашем сервисе.</p>
          <p>Пожалуйста, дождитесь полной загрузки данных, в среднем это занимает 2-4 часа и не зависит от нас, так как первичные данные мы получаем от официального API маркетплейса. </p>
        </div>
      </div>
    </div>
  </div>

  <!--Всплывающая инструкция Вб-->
  <div class="offcanvas offcanvas-start" tabindex="-1" id="howToWb" aria-labelledby="howToWbLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="howToWbLabel">Как подключить магазин Wildberries</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div>
            <p class="mt-2">1. Создание токена (ключа подключения)</p>
            <p>Зайдите в раздел настройки в Кабинете селлера на Wildberries. Перейдите во вкладку Доступ к API (или <a href="https://seller.wildberries.ru/supplier-settings/access-to-api">нажмите сюда</a>). </p>
            <img src="../assets/images/about_us/img1.png" alt="" style="max-width: 100%;">

            <p class="mt-4">2. Нажмите кнопку Создать новый токен.</p>
            <img src="../assets/images/about_us/img2.png" alt="" style="max-width: 100%;">

            <p class="mt-4">3. В открывшемся окне введите имя нового токена и выберите все методы API. Важно отметить галочкой пункт «Только на чтение». И нажмите кнопку скопировать токен. </p>
            <p><i>Важно: Созданный токен не позволяет нам вносить изменения в ваш магазин, а только позволяет собирать и обрабатывать статистику.</i></p>
            <img src="../assets/images/about_us/img3.png" alt="" style="max-width: 100%;">

            <p class="mt-4">4. Скопируйте токен</p>
            <img src="../assets/images/about_us/img4.png" alt="" style="max-width: 100%;">

            <p class="mt-4">5. Вставьте токен в поле Ключ API WB на сайте.</p>
            <img src="../assets/images/about_us/img5.png" alt="" style="max-width: 100%;">

            <span role="button" data-bs-dismiss="offcanvas"><button class="btn btn-primary mt-4 mb-3" data-bs-toggle="modal" data-bs-target="#addMarketplaceAccount">Подключить магазин</button></span>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-start" tabindex="-1" id="howToOzon" aria-labelledby="howToOzonLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="howToOzonLabel">Как подключить магазин Ozon</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div>
      <p class="mt-2">1. Зайди в настройки в Кабинете селлера Ozon в раздел API ключи (или <a href="https://seller.ozon.ru/app/settings/api-keys?currentTab=sellerApi">нажмите сюда</a>) </p>

      <p class="mt-4">2. В разделе Seller API (указатель 1) скопируйте Client ID (указатель 2) и вставьте в поле Client Id API Ozon.</p>
      <img src="../assets/images/about_us/img6.png" alt="" style="max-width: 100%;">

      <p class="mt-4">3. Нажмите сгенерировать ключ</p>
      <img src="../assets/images/about_us/img7.png" alt="" style="max-width: 100%;">

      <p class="mt-4">4. Введите название токена (указатель 1) и в Типы токена выберете Admin real only (указатель 2)</p>
      <img src="../assets/images/about_us/img8.png" alt="" style="max-width: 100%;">

      <p class="mt-4">5. Скопируйте получившийся ключ и вставьте в поле Ключ API Ozon на сайте.</p>
      <img src="../assets/images/about_us/img9.png" alt="" style="max-width: 100%;">

      <p class="mt-4">6. Перейдите в меню Performance API (указатель 1) и нажмите Создать аккаунт (указатель 2)</p>
      <img src="../assets/images/about_us/img10.png" alt="" style="max-width: 100%;">

      <p class="mt-4">7. Нажмите на созданный аккаунт</p>
      <img src="../assets/images/about_us/img11.png" alt="" style="max-width: 100%;">

      <p class="mt-4">8. Нажмите на Добавить ключ</p>
      <img src="../assets/images/about_us/img12.png" alt="" style="max-width: 100%;">

      <p class="mt-4">9. Нажмите Добавить </p>
      <img src="../assets/images/about_us/img13.png" alt="" style="max-width: 100%;">

      <p class="mt-4">10.	Скопируйте Client Id (указатель 1) и вставьте в поле Performance Client Id API Ozon на сайте. Скопируйте Client Secret (указатель 2) и вставьте в поле Performance Client Secret API Ozon</p>
      <img src="../assets/images/about_us/img14.png" alt="" style="max-width: 100%;">

            <span role="button" data-bs-dismiss="offcanvas"><button class="btn btn-primary mt-4 mb-3" data-bs-toggle="modal" data-bs-target="#addMarketplaceAccountOzon">Подключить магазин</button></span>
    </div>
  </div>
</div>

  <!-- Модальное окно для добавления мп -->
  <div class="modal fade" id="addMarketplaceAccount" tabindex="-1" aria-labelledby="addMarketplaceAccountLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <img src="../assets/images/wb.png" style="height: 35px;" class="me-2" alt="Wildberries">
          <h5 class="modal-title" id="addMarketplaceAccountLabel">Добавить аккаунт маркетплейса</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <a class="d-block mb-3 mt-0" data-bs-toggle="offcanvas" data-bs-target="#howToWb" role="button"><span data-bs-dismiss="modal" role="button">Как подключить магазин Wildberries</span></a>
          <div class="modal-loader d-flex justify-content-center" v-if="newStoreLoad">
            <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
          <div class="marketplaceAccountForm js-marketAccForm-wb" v-else>
            <form @submit.prevent="add">
              <input type="hidden" name="type" value="1">
              <div class="mb-3">
                <label for="accontName" class="form-label">Название магазина</label>
                <input class="form-control" name="title" type="text" id="accontName" placeholder="Название магазина" required="" autocomplete="off" v-model="storeData.title">
                <div class="small text-muted">Отображается внутри сервиса</div>
              </div>
              <div class="mb-3">
                <label for="token64Wb" class="form-label">Ключ API WB</label>
                <input type="text" class="form-control" name="apiKey" id="token64Wb" placeholder="Ключ API WB" required="" autocomplete="off" v-model="storeData.apiKey">
              </div>

              <!-- <div class="mb-3">
                <div class="form-floating">
                  <select name="tax_variant" class="form-select" id="taxVariantEdit" v-model="storeData.taxKey">
                    <option value="0">Не выбрано</option>
                    <option value="1" data-use-nds="">УСН Доходы</option>
                    <option value="2" data-use-nds="">УСН Доходы минус расходы</option>
                    <option value="3" data-use-nds="">ОСНО без НДС</option>
                    <option value="4" data-use-nds="1">ОСНО с НДС</option>
                  </select>
                  <label for="taxVariantEdit">Система налогообложения</label>
                </div>
              </div>

              <div class="mb-3" id="tax_setting">
                <div class="form-floating">
                  <input class="form-control" type="text" name="base_tax" value="" id="baseTaxEdit" placeholder="Введите ставку по налогу" autocomplete="off" v-model="storeData.taxValue" pattern="[0-9]{1,3}">
                  <label for="baseTaxEdit">Процентная ставка (%)</label>
                </div>
              </div>

              <div class="mb-3" id="nds_setting">
                <div class="form-floating">
                  <input class="form-control" type="text" name="nds_tax" value="" id="ndsTaxEdit" placeholder="Введите ставку НДС" autocomplete="off" v-model="storeData.ndsValue" pattern="[0-9]{1,3}">
                  <label for="ndsTaxEdit">Процентная ставка НДС (%)</label>
                </div>
              </div> -->

              <div class="mb-3 text-center">
                <button class="btn btn-primary"> Добавить
                </button>
              </div>
            </form>
            <p><small>После добавление нового магазина и успешной верификации ключей, начинается начальная загрузка данных за последние 90 дней относительно даты создания магазина в нашем сервисе.</small></p>
          </div>
          
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="addMarketplaceAccountOzon" tabindex="-1" aria-labelledby="addMarketplaceAccountLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <img src="../assets/images/ozon.png" style="height: 35px;" class="me-2" alt="Ozon">
          <h5 class="modal-title" id="addMarketplaceAccountLabel">Добавить аккаунт маркетплейса</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="modal-loader d-flex justify-content-center" v-if="newStoreLoad">
            <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
          <div class="marketplaceAccountForm js-marketAccForm-wb" v-else>
            <a class="d-block mb-3 mt-0" data-bs-toggle="offcanvas" data-bs-target="#howToOzon" role="button"><span data-bs-dismiss="modal" role="button">Как подключить магазин Ozon</span></a>
            <form @submit.prevent="add">
              <input type="hidden" name="type" value="1">
              <div class="mb-3">
                <label for="accontName" class="form-label">Название магазина</label>
                <input class="form-control" name="title" type="text" id="accontName" placeholder="Название магазина" required="" autocomplete="off" v-model="storeOzonData.title">
                <div class="small text-muted">Отображается внутри сервиса</div>
              </div>
              <div class="mb-3">
                <label for="token64Wb" class="form-label">Ключ API Ozon</label>
                <input type="text" class="form-control" name="apiKey" placeholder="Ключ API Ozon" required="" autocomplete="off" v-model="storeOzonData.apiKey">
              </div>
              <div class="mb-3">
                <label class="form-label">Client Id API Ozon</label>
                <input type="number" class="form-control" name="clientId" placeholder="Client Id API Ozon" required="" autocomplete="off" v-model="storeOzonData.clientId">
              </div>
              <div class="mb-3">
                <label for="token64Wb" class="form-label">Performance Client Id API Ozon</label>
                <input type="text" class="form-control" name="clientId" placeholder="Performance Client Id API Ozon" required="" autocomplete="off" v-model="storeOzonData.advClientId">
              </div>
              <div class="mb-3">
                <label for="token64Wb" class="form-label">Performance Client Secret API Ozon</label>
                <input type="text" class="form-control" name="clientId" placeholder="Performance Client Secret API Ozon" required="" autocomplete="off" v-model="storeOzonData.advClientSecret">
              </div>

              <!-- <div class="mb-3">
                <div class="form-floating">
                  <select name="tax_variant" class="form-select" id="taxVariantEdit" v-model="storeOzonData.taxKey">
                    <option value="0">Не выбрано</option>
                    <option value="1" data-use-nds="">УСН Доходы</option>
                    <option value="2" data-use-nds="">УСН Доходы минус расходы</option>
                    <option value="3" data-use-nds="">ОСНО без НДС</option>
                    <option value="4" data-use-nds="1">ОСНО с НДС</option>
                  </select>
                  <label for="taxVariantEdit">Система налогообложения</label>
                </div>
              </div>

              <div class="mb-3" id="tax_setting">
                <div class="form-floating">
                  <input class="form-control" type="text" name="base_tax" value="" id="baseTaxEdit" placeholder="Введите ставку по налогу" autocomplete="off" v-model="storeOzonData.taxValue" pattern="[0-9]{1,3}">
                  <label for="baseTaxEdit">Процентная ставка (%)</label>
                </div>
              </div>

              <div class="mb-3" id="nds_setting">
                <div class="form-floating">
                  <input class="form-control" type="text" name="nds_tax" value="" id="ndsTaxEdit" placeholder="Введите ставку НДС" autocomplete="off" v-model="storeOzonData.ndsValue" pattern="[0-9]{1,3}">
                  <label for="ndsTaxEdit">Процентная ставка НДС (%)</label>
                </div>
              </div> -->

              <div class="mb-3 text-center">
                <button class="btn btn-primary"> Добавить
                </button>
              </div>
            </form>
            <p><small>После добавление нового магазина и успешной верификации ключей, начинается начальная загрузка данных за последние 90 дней относительно даты создания магазина в нашем сервисе.</small></p>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <!-- Модальное окно для редактирования аккаунта -->
  <!-- <div class="modal fade" id="editMarketplaceAccount" tabindex="-1" aria-labelledby="editMarketplaceAccountLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <img src="../assets/images/ozon.png" style="height: 35px;" class="me-2" alt="Ozon" v-if="itemEdit.marketplace == 'ozon'">
          <img src="../assets/images/wb.png" style="height: 35px;" class="me-2" alt="Wildberries" v-if="itemEdit.marketplace == 'wildberries'">
          <h5 class="modal-title" id="editMarketplaceAccountLabel">Изменить {{ itemEdit.title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="my-5 d-flex justify-content-center" v-if="loading">
            <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
          <div class="editMarketplaceAccountContent" v-else>
            <input type="text" class="form-control d-block" placeholder="Новое название магазина" v-model="itemEdit.new_title">
            <button class="btn btn-primary mt-2" @click="editStore()">Сохранить</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Модальное окно для редактирования аккаунта -->
  <!-- <div class="modal fade" id="editMarketplaceAccountTaxSettings" tabindex="-1" aria-labelledby="editMarketplaceAccountTaxSettingsLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editMarketplaceAccountTaxSettingsLabel">Изменить настройки налога аккаунта маркетплейса</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="editMarketplaceAccountTaxSettingsContent">
  <form action="/api/v1/mp/account/bdb93936-2286-4bcb-8c37-5ec323106f40/tax-settings-edit" method="post">

    <div class="mb-3">
      <div class="form-floating">
        <select name="tax_variant" class="form-select" id="taxVariantEdit">
          <option value="0">Не выбрано</option>
          <option value="1" data-use-nds="">УСН Доходы</option>
          <option value="2" data-use-nds="">УСН Доходы минус расходы</option>
          <option value="3" data-use-nds="">ОСНО без НДС</option>
          <option value="4" data-use-nds="1">ОСНО с НДС</option>
        </select>
        <label for="taxVariantEdit">Система налогообложения</label>
      </div>
    </div>

    <div class="mb-3 d-none" id="tax_setting">
      <div class="form-floating">
        <input class="form-control" type="text" name="base_tax" value="" id="baseTaxEdit" placeholder="Введите ставку по налогу" autocomplete="off">
        <label for="baseTaxEdit">Процентная ставка</label>
      </div>
    </div>

    <div class="mb-3 d-none" id="nds_setting">
      <div class="form-floating">
        <input class="form-control" type="text" name="nds_tax" value="" id="ndsTaxEdit" placeholder="Введите ставку НДС" autocomplete="off">
        <label for="ndsTaxEdit">Процентная ставка НДС</label>
      </div>
    </div>

    <div class="mb-3 text-center">
      <button class="btn btn-primary" type="submit">
         Сохранить
      </button>
    </div>
  </form>
</div>
</div> -->
        <!-- </div>
      </div> -->
    <!-- </div> -->
  </div>
</div>
</template>

<style lang="scss">
// @import url('../assets/style/userstores.scss');
</style>

<script>
import axios from 'axios';

export default{
    data(){
        return{
            addStore: '',
            storeType: 1,
            storeData: {
              title: '',
              apiKey: '',
              taxKey: '0',
              taxValue: '0',
              ndsValue: '0',
              user_id: this.$store.state.user_info.id,
              store: 'wildberries',
              mail: this.$store.state.user_info.mail,
              name: this.$store.state.user_info.name
            },
            storeOzonData: {
              title: '',
              apiKey: '',
              clientId: '',
              advClientId: '',
              advClientSecret: '',
              taxKey: '0',
              taxValue: '0',
              ndsValue: '0',
              user_id: this.$store.state.user_info.id,
              store: 'ozon',
              mail: this.$store.state.user_info.mail,
              name: this.$store.state.user_info.name
            },
            userStores: [],
            newStoreLoad: false,
            itemEdit: {},
            loading: false
        }
    },
    methods: {
      add(){
        this.storeData.type = this.storeType
        if(this.addStore == 'wildberries'){
          if(this.storeData.apiKey.length > 10){
            axios.post('https://tapwill.ru/api/add_store.php', this.storeData).then((response)=>{
              this.newStoreLoad = true
              console.log(response.data)
              let start = moment().subtract(90, 'days');
              let end = moment();
              let post_data = {
                start_date: start,
                end_date: end,
                apiKey: this.storeData.apiKey,
                store: this.storeData.title,
                user_id: this.$store.state.user_info.id,
                mail: this.$store.state.user_info.mail,
                name: this.$store.state.user_info.name
              }

              axios.post('https://tapwill.ru/api/add_data.php', post_data).then((response)=>{
                console.log(response)
                window.location.reload();
              })
            })
          }
          else{
            alert('Неверный API ключ')
          }
        }
        else{
          if(this.storeOzonData.apiKey.length > 10 && this.storeOzonData.apiKey.includes('-') && String(this.storeOzonData.clientId).length > 3 && this.storeOzonData.advClientId.includes('@advertising.performance.ozon.ru') && this.storeOzonData.advClientSecret.length > 10){
            axios.post('https://tapwill.ru/api/add_store_ozon.php', this.storeOzonData).then((response)=>{
            this.newStoreLoad = true
            console.log(response.data)
            let post_data = {
              apiKey: this.storeOzonData.apiKey,
              clientId: this.storeOzonData.clientId,
              advClientId: this.storeOzonData.advClientId,
              advClientSecret: this.storeOzonData.advClientSecret,
              store: this.storeOzonData.title,
              user_id: this.$store.state.user_info.id,
              mail: this.$store.state.user_info.mail,
              name: this.$store.state.user_info.name
            }

            axios.post('https:///tapwill.ru/api/add_data_ozon.php', post_data).then((response)=>{
              console.log(response)
              window.location.reload();
            })
          })
          }
          else{
            console.log(1)
            alert('Неверные ключи API')
            console.log(this.storeOzonData.apiKey.length > 10, this.storeOzonData.apiKey.includes('-'), String(this.storeOzonData.clientId).length > 3, this.storeOzonData.advClientId.includes('@advertising.performance.ozon.ru'), this.storeOzonData.advClientSecret.length > 10)
          }
        }
      },
      deleteStore(store_title){
        let conf = confirm('Вы точно хотите удалить магазин?')

        if(conf == true){
          axios.post('https://tapwill.ru/api/delete_store.php', {store: store_title, user_id: this.$store.state.user_info.id, mail: this.$store.state.user_info.mail, name: this.$store.state.user_info.name}).then((response)=>{
              console.log(response)
              alert('Магазин удален')
              window.location.reload()
          })
        }
      },
      updateInfo(){
        axios.post('https://tapwill.ru/api/get_store.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
          this.userStores = response.data
          console.log(this.userStores)
        })
      },
      changeCheckedStore(id, index){
        if(this.userStores[index]['checkboxValue'] == true){
          this.userStores[index]['active'] = 2
          this.userStores[index]['checkboxValue'] = !this.userStores[index]['checkboxValue']
        }
        else{
          this.updateInfo()
        }
      },
      editStore(){
        if(this.itemEdit.new_title && this.itemEdit.new_title !== ""){
          this.loading = true
          axios.post('https://tapwill.ru/api/edit_store.php', {user_id: this.$store.state.user_info.id, title: this.itemEdit.title, id: this.itemEdit.id, new_title: this.itemEdit.new_title}).then((response)=>{
            if(response.status == 200){
              window.location.reload()
            }
          })
        }
      }
    },
    created(){
      this.updateInfo()
    }
}
</script>