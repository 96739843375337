<template>
  <div class="modal fade" id="mobile_modal_info" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="mobile_modal_info">Подробная информация</h5>
        <button type="button" data-bs-dismiss="modal" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="table">
          <table class="custom-table">
            <tbody v-if="items.length !== 0" class="modal-info-table">
              <tr>
                <td>Дата</td>
                <td>{{ items[itemId].date }}</td>
              </tr>
             <tr>
              <td>Артикул продавца</td>
              <td>
                {{ items[itemId].supplierArticle }}
              </td>
             </tr>
             <tr>
              <td>Артикул {{ items[itemId].marketplace }}</td>
              <td>{{ items[itemId].nmId }}</td>
             </tr>
             <tr>
              <td>Показы</td>
              <td>{{ items[itemId].views }}</td>
             </tr>
              <tr>
                <td>Клики</td>
                <td>{{ items[itemId].clicks }}</td>
              </tr>
              <tr>
                <td>Добавлено в корзину</td>
                  <td>{{ items[itemId].atbs }}</td>
              </tr>
             <tr>
              <td>Оформлено заказов</td>
              <td>{{ items[itemId].orders }}</td>
             </tr>
              <tr>
                <td>Сумма заказов, ₽</td>
                <td>{{ items[itemId].sum_price }}</td>
              </tr>
              <tr>
                <td>Средняя CPM, ₽</td>
                <td>{{ items[itemId].cpm }}</td>
              </tr>
              <tr>
                <td>Расход, ₽</td>
                <td>{{ items[itemId].sum }}</td>
              </tr>
              <tr>
                <td>CTR, %</td>
                <td>{{ items[itemId].ctr }}</td>
              </tr>
              <tr>
                <td>CPC, ₽</td>
                <td>{{ items[itemId].cpc }}</td>
              </tr>
              <tr>
                <td>CR, %</td>
                <td>{{ items[itemId].cr }}</td>
              </tr>
              <tr>
                 <td>CPA, ₽</td>
                 <td>{{ items[itemId].cpa }}</td>
              </tr>
              <tr>
                <td>Источник показа</td>
                <td>{{ items[itemId].appType }}</td>
              </tr>
              <tr>
                <td>Рекламная кампания</td>
                 <td>{{ items[itemId].name }}</td>
              </tr>
              <tr>
                <td>Магазин</td>
                <td>{{ items[itemId].store }}</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


    <div class="container-fluid">

      <div class="alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
      </div>

  <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
    <div class="col content-title">
      <h1>Журнал статистики по рекламным кампаниям</h1></div>
  </div>

  <div class="content-block">
    <nav class="nav nav-tabs mb-3 mt-5">
      <li class="nav-item">
        <a href="/advert/stat" class="nav-link active">Статистика по рекламе</a>
      </li>
      <li class="nav-item">
        <a href="/advert/list" class="nav-link">Список рекламных кампаний</a>
      </li>
    </nav>
  </div>
  <div class="content-block">
    <div class="accordion d-lg-none" id="filterOpen">
            <div class="accordion-item border-0">
                <h2 class="accordion-header">
                <button class="accordion-button collapsed ps-0" style="width: fit-content;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                   <span class="pe-3">Показать параметры</span>
                 </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#filterOpen">
                <div class="accordion-body">

                  <div class="pageFilter">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
          <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
            <label for="period_a0acfa46" class="form-label">Период</label>
            <div class="input-group">
              <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="period_a0acfa46" autocomplete="off"><span class="input-group-text"><label for="period_a0acfa46"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
            <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="">
            <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="">
          </div>
          <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brand_8c4d3a94" class="form-label">Бренд</label>
                <select class="form-select" v-model="filter.brand">
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store">
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace">
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Артикул продавца</label>
                <input type="text" class="form-control" v-model="filter.article" autocomplete="off">
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter(0)">Применить</button>
              </div>
              <div class="btn-group"><a href="/advert/stat" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>

                  </div>
                  </div>
                  </div>
                  </div>

    <div class="pageFilter d-lg-block d-none">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
          <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
            <label for="period_a0acfa46" class="form-label">Период</label>
            <div class="input-group">
              <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="period_a0acfa46" autocomplete="off"><span class="input-group-text"><label for="period_a0acfa46"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
            <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="">
            <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="">
          </div>
          <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brand_8c4d3a94" class="form-label">Бренд</label>
                <select class="form-select" v-model="filter.brand">
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store">
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace">
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Артикул продавца</label>
                <input type="text" class="form-control" v-model="filter.article" autocomplete="off">
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter(0)">Применить</button>
              </div>
              <div class="btn-group"><a href="/advert/stat" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

  <div v-if="$store.state.user_info.check == 0">
  <div class="modal-loader d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
  </div>
  <div class="content-block" v-else>
    <div class="table">
      <table class="custom-table d-sm-block d-none">
        <thead>
          <tr>
            <td>Дата</td>
            <td>Артикул</td>
            <td>Показы</td>
            <td>Клики</td>
            <td>Добавлено
              <br>в корзину</td>
            <td>Оформлено
              <br>заказов</td>
            <td>Сумма
              <br>заказов, ₽</td>
            <td>Средняя
              <br>CPM, ₽</td>
            <td>Расход, ₽</td>
            <td>CTR, %</td>
            <td>CPC, ₽</td>
            <td>CR, %</td>
            <td>CPA, ₽</td>
            <td>Источник показа</td>
            <td>Рекламная
              <br>кампания</td>
            <td>Магазин</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" class="table-light">
            <td>{{ item.date }}</td>
            <td>
              {{ item.supplierArticle }}
              <br>Артикул: {{ item.nmId }}
            </td>
            <td>{{ item.views }}</td>
            <td>{{ item.clicks }}</td>
            <td>{{ item.atbs }}</td>
            <td>{{ item.orders }}</td>
            <td>{{ item.sum_price }}</td>
            <td>{{ item.cpm }}</td>
            <td>{{ item.sum }}</td>
            <td>{{ item.ctr }}</td>
            <td>{{ item.cpc }}</td>
            <td>{{ item.cr }}</td>
            <td>{{ item.cpa }}</td>
            <td>{{ item.appType }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.store }}</td>
          </tr>
        </tbody>
      </table>

      <table class="custom-table d-sm-none table-light">
            <thead>

              <tr>
                <td>Артикул</td>
                <td>Расход, ₽</td>
                <td></td>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in items">
                
                <td>
                  <div class="py-2">
                    <span class="d-block">{{ item.date }}</span> 
                    <span class="d-block mt-1">{{ item.supplierArticle }}</span> 
                  </div>
                </td>
                <td>{{ item.sum }}</td>
                <td><a class="show_mobile_modal_icon" data-bs-toggle="modal" data-bs-target="#mobile_modal_info" @click="itemId = index"><i class="bi bi-eye"></i></a></td>
              </tr>
            </tbody>
          </table>

    </div>
  </div>
  <div class="order-pagination">
          <nav aria-label="Page navigation example">
            <ul class="pagination mt-3">
              <li class="page-item" style="cursor: pointer" :class="{active: pageNum == page+1}" v-for="pageNum in pages_count" @click="applyFilter(pageNum-1)">
                <span class="pagination-button">{{ pageNum }}</span>
              </li>
            </ul>
          </nav>
    </div>
</div>
</div>
</template>

<script>
import axios from 'axios'

export default{
  data(){
    return {
      items: [],
      items_result: [],
      page: 0,
      pages_count: 0,
      filter: {
        store: '',
        brand: '',
        article: '',
        marketplace: ''
      },
      stores: [],
      brands: [],
      loading: true,
      itemId: 0
    }
  },
  methods: {
    loadData(start, end){
      this.loading = true
      let post_data = {
        start_date: start,
        end_date: end,
        store: this.filter.store,
        brand: this.filter.brand,
        marketplace: this.filter.marketplace,
        article: this.filter.article,
        user_id: this.$store.state.user_info.id,
        page: this.page
      }

      axios.post('https://tapwill.ru/api/get_adv_articles.php', post_data).then((response)=>{
        this.items = response.data[0]
        this.pages_count = response.data[1]
        console.log(this.items)
        this.loading = false
    })
    },
    applyFilter(n){
      this.page = n
      this.loadData($('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD'),  $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD'))
    }
  },
  created(){
    this.loadData(moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    axios.post('https://tapwill.ru/api/get_store.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.stores = response.data
      console.log(this.stores)
    })
    axios.post('https://tapwill.ru/api/get_brands.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.brands = response.data
      console.log(this.brands)
    })
  },
  mounted(){
    let start = moment().subtract(7, 'days');
    let end = moment();

    function cb(start, end) {
        $('.js-daterangepicker span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    $('.js-daterangepicker').daterangepicker({
      "locale": {
        "format": "DD.MM.YYYY",
        "separator": " - ",
        "applyLabel": "Сохранить",
        "cancelLabel": "Назад",
        "daysOfWeek": [
            "Вс",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ],
        "firstDay": 1
    },
      
      opens: 'left',
      autoUpdateInput: true,
      startDate: start,
      endDate: end,
      ranges: {
           'Сегодня': [moment(), moment()],
           'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
           'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
           'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
           'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
           'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      }, (start, end) => {
        $('.js-daterangepicker').data('daterangepicker').startDate = start
        $('.js-daterangepicker').data('daterangepicker').endDate = end
        this.loadData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
      }, cb);


    cb(start, end);

    $('li[data-range-key="Custom Range"]').text('Произвольно')
  }
}
</script>