import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/views/MainPage.vue'
import Orders from '@/views/Orders.vue'
import Dashboard from '@/views/Dashboard.vue'
import ItemsInfo from "@/views/ItemsInfo.vue"
import ABC from "@/views/ABC.vue"
import AdvertStat from '@/views/AdvertStat.vue'
import AdvertList from '@/views/AdvertList.vue'
import Geography from '@/views/Geography.vue'
import ProfitReport from '@/views/ProfitReport.vue'
import UploadCost from '@/views/UploadCost.vue'
import RestItems from '@/views/RestItems.vue'
import Auth from '@/views/Auth.vue'
import Reg from '@/views/Reg.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ProfileEdit from '@/views/ProfileEdit.vue'
import UserStores from '@/views/UserStores.vue'
import Tariff from '../views/Tariff.vue'
import AdvertInfo from '@/views/AdvertInfo.vue'
import AboutUs from '@/views/AboutUs.vue'
import ActionsOzon from '@/views/ActionsOzon.vue'

const routes = [
  {
    path: '/',
    component: MainPage
  },
  {
    path: '/orders',
    component: Orders
  },
  {
    path: '/orders/:getArticle',
    component: Orders,
    props: true
  },
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/itemsinfo',
    component: ItemsInfo
  },
  {
    path: '/itemsinfo/:getArticle',
    component: ItemsInfo,
    props: true
  },
  {
    path: '/abc',
    component: ABC
  },
  {
    path: '/advert',
    component: AdvertStat
  },
  {
    path: '/advert/stat',
    component: AdvertStat
  },
  {
    path: '/advert/:id',
    component: AdvertInfo,
    props: true
  },
  {
    path: '/advert/list',
    component: AdvertList
  },
  {
    path: '/geography',
    component: Geography
  },
  {
    path: '/profit',
    component: ProfitReport
  },
  {
    path: '/uploadcost',
    component: UploadCost
  },
  {
    path: '/restitems',
    component: RestItems
  },
  {
    path: '/auth',
    component: Auth
  },
  {
    path: '/reg',
    component: Reg
  },
  {
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    path: '/profile-edit',
    component: ProfileEdit
  },
  {
    path: '/user-stores',
    component: UserStores
  },
  {
    path: '/tariff',
    component: Tariff
  },
  {
    path: '/how-to-use',
    component: AboutUs
  },
  {
    path: '/actions_ozon',
    component: ActionsOzon
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
